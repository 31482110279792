/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.staff-one {
 /* --background: url(/assets/images/webeins_transparent.png) var(--ion-color-light) no-repeat bottom right;*/
}

ion-content.staff-one {
  top: 10px;
}

app-header::before {
  height: 20px;
  background: var(--ion-color-primary);
  content: '';
  position: absolute;
  z-index: 10;
  width: 100%;
  top: 56px;
}
app-header::after {
  content: '';
  height: 20px;
  background: var(--ion-color-primary-contrast);
  position: absolute;
  z-index: 11;
  border-top-left-radius: 20px;
  width: 100%;
  border-top-right-radius: 20px;
}
app-header.next-color-primary::after {
  background: transparent;
}
.ios app-header::before {
  top: 44px;
}

.text-center {
  text-align: center;
}
.d-flex {
  display: flex;
}

.icon-xl {
  font-size: 64px;
}

.flex-column {
  flex-direction: column;
}
.flex-center {
  justify-content: center;
}

.fill-remaining-space {
  flex: 1 1 auto;
}
a[href] {
  text-decoration: none;
}

ion-card.highlight {
  border-radius: 8px !important;
}
ion-card.highlight small {
  font-size: 12px;
}
ion-card-title {
  font-weight: normal;
  font-size: 18px;
}
ion-card.highlight ion-card-title {
  color: var(--ion-color-primary);
}

ion-item > app-highlight-card {
  width: 100%;
}
ion-item-divider.divider-staff-one {
  border-bottom: none;
  background: var(--ion-color-contrast);
  color: var(--ion-color-base);
}
.ios ion-title.modal-extended-title {
  position: relative;
    text-align: left;
    padding: 10px;
}

.chip-bright {
  background: var(--ion-color-tint);
  color: var(--ion-color-shade);
}
.chip-contrast {
  background: var(--ion-color-shade);
  color: var(--ion-color-contrast);
}
.chip-cornered {
  border-radius: 4px;
  font-size: 12px;
}

.chip-emphasized {
  background: var(--ion-color-base);
  color: var(--ion-color-contrast);
  border-color: var(--ion-color-base);
  font-size: 24px;
}

.chip-emphasized.chip-outline {
  background: var(--ion-color-contrast);
  color: var(--ion-color-base);
}
.chip-emphasized > small {
  font-size: 11px;
  align-self: flex-end;
  margin-left: 5px;
}

.color-dark {
  color: var(--ion-color-light-contrast)
}

.text-right {
  text-align: right;
}

.item-native {
  color: var(--ion-item-color);
}


.popover {
  height: auto !important;
}
.popover ion-header-bar {
  position: relative;
}
.popover ion-content {
  top: 0;
  position: relative;
}
